import SubNavbar from "./subnavbar";
import './landing.css';
import { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";

function Landing() {
  const [activeCategories, setActiveCategories] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/products`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleToggle = (category) => {
    setActiveCategories((prevCategories) =>
      prevCategories.includes(category)
        ? prevCategories.filter((cat) => cat !== category)
        : [...prevCategories, category]
    );
  };

  const filteredProducts = activeCategories.length
    ? products.filter((product) =>
        product.category.some((cat) => activeCategories.includes(cat))
      )
    : products;

  return (
    <div className="landing">
      <SubNavbar />
      
      <div className="banner-container">
        <img src="/assets/Banner (2).png" className="img-fluid" alt="Banner" />
      </div>

      <div className="toggle_container">
        <div
          className={`toggle-text ${activeCategories.includes('Featured') ? 'active' : ''}`}
          onClick={() => handleToggle('Featured')}
          style={{color: '#000', fontWeight: '600'}}
        >
          Featured
        </div>
        <div
          className={`toggle-text ${activeCategories.includes('On Sale') ? 'active' : ''}`}
          onClick={() => handleToggle('On Sale')}
          style={{color: '#000', fontWeight: '600'}}
        >
          On Sale
        </div>
        <div
          className={`toggle-text ${activeCategories.includes('Top Rated') ? 'active' : ''}`}
          onClick={() => handleToggle('Top Rated')}
          style={{color: '#000', fontWeight: '600'}}
        >
          Top Rated
        </div>
      </div>

      <div className="products-container">
        {filteredProducts.map((product) => (
          <Link key={product._id} to={`/product/${product._id}`} className="product-item" style={{textDecoration: 'none'}}>
            <img 
              src={product.images[0]} 
              alt={product.productName} 
              className="product-image" 
            />
            <p className="product-name" style={{color: '#000'}}>{product.productName}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Landing;
