import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import axios from 'axios';

function ProductListing() {
    const [formData, setFormData] = useState({
        productName: '',
        description: '',
        category: [],
        SKU: '',
        price: '',
        discount: '',
        stockQuantity: '',
        minOrderQuantity: '',
        shippingOption: '',
        images: []
    });

    const [radioValue, setRadioValue] = useState('11');
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const navigate = useNavigate();

    const radios = [
        { name: 'Standard Shipping 5-6 Business Days', value: '11' },
        { name: 'Express Shipping 2-3 Business Days', value: '12' },
        { name: 'Overnight Shipping 1 Business Day', value: '13' },
        { name: '2 Days Delivery', value: '14' },
        { name: 'Same Day Delivery', value: '15' },
    ];

    useEffect(() => {
        // Load categories from localStorage on component mount
        const storedCategories = JSON.parse(localStorage.getItem('categories'));
        setCategories(storedCategories || ["Featured", "On Sale", "Top Rated", "Best Seller", "Limited Edition"]);
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === 'category') {
            setFormData((prevData) => {
                const updatedCategories = checked
                    ? [...prevData.category, value]
                    : prevData.category.filter((category) => category !== value);

                return { ...prevData, category: updatedCategories };
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, images: Array.from(e.target.files) });
    };

    const handleNewCategory = () => {
        if (newCategory && !categories.includes(newCategory)) {
            const updatedCategories = [...categories, newCategory];
            setCategories(updatedCategories);
            localStorage.setItem('categories', JSON.stringify(updatedCategories)); // Save updated list to localStorage
            setNewCategory(''); // Reset the new category input field
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === 'images') {
                formData.images.forEach((file) => data.append('images', file));
            } else if (key === 'category') {
                formData.category.forEach((cat) => data.append('category', cat));
            } else {
                data.append(key, formData[key]);
            }
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/submit`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(res.data);
            alert('Product listed successfully!');
            navigate('/product-success', { state: { formData } });
        } catch (err) {
            console.error("Axios error:", err);
            alert('Error submitting the form.');
        }
    };

    return (
        <div className="productListing mt-1">
            <div className="container">
                <div className='container' style={{ backgroundColor: '#B1B1B1', fontSize: '28px', width: '100%', margin: '0' }}>
                    <h1>List A NEW PRODUCT</h1>
                </div>
                <div className="container row mt-4">
                    <div className="col-md-12">
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Enter product details</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="productName"
                                    placeholder="Enter name of the product"
                                    onChange={handleChange}
                                />
                                <br />
                                <Form.Control
                                    type="text"
                                    name="description"
                                    placeholder="Description of the product"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Select Category</Form.Label>
                                {categories.map((category, index) => (
                                    <Form.Check
                                        key={index}
                                        type="checkbox"
                                        name="category"
                                        label={category}
                                        value={category}
                                        onChange={handleChange}
                                    />
                                ))}
                                <Form.Control
                                    type="text"
                                    placeholder="Add a new category"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                    className="mt-2"
                                />
                                <Button variant="primary" onClick={handleNewCategory} className="mt-2">
                                    Add Category
                                </Button>
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="SKU"
                                    placeholder="Enter product SKU"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>Upload Product Images</Form.Label>
                                <Form.Control
                                    type="file"
                                    name="images"
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Set pricing in inventory</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="price"
                                    placeholder="Enter Selling Price in Rs"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="discount"
                                    placeholder="Discounts if applicable"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="stockQuantity"
                                    placeholder="Available Stock Quantity"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group>
                                <Form.Control
                                    type="text"
                                    name="minOrderQuantity"
                                    placeholder="Minimum Order Quantity if applicable"
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <div>Shipping Options</div>
                            <ButtonGroup>
                                {radios.map((radio, idx) => (
                                    <ToggleButton
                                        key={idx}
                                        id={`radio-${idx}`}
                                        type="radio"
                                        variant="outline-primary"
                                        name="shippingOption"
                                        value={radio.value}
                                        checked={radioValue === radio.value}
                                        onChange={(e) => {
                                            setRadioValue(e.currentTarget.value);
                                            handleChange(e);
                                        }}
                                        style={{
                                            marginRight: '10px',
                                            backgroundColor: radioValue === radio.value ? '#00A9B4' : '',
                                            borderColor: radioValue === radio.value ? '#00A9B4' : '#000',
                                            color: radioValue === radio.value ? 'white' : '#000',
                                        }}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>

                            <Button type="submit" className="mt-5" style={{ backgroundColor: '#FFD700', color: '#000', fontWeight: '700', padding: '10px', borderColor: '#FFD700', fontSize: '20px' }}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductListing;
