import React, { createContext, useContext, useEffect, useReducer } from 'react';

// Create a context for the cart
const CartContext = createContext();

const getCartItemsFromLocalStorage = () => {
    const cartItems = localStorage.getItem('cartItems');
    return cartItems ? JSON.parse(cartItems) : [];
};

const getWishlistItemsFromLocalStorage = () => {
    const wishlistItems = localStorage.getItem('wishlistItems');
    return wishlistItems ? JSON.parse(wishlistItems) : [];
};

// Initial state for the cart and wishlist
const initialState = {
    cartItems: getCartItemsFromLocalStorage() || [], // Ensure it's an array
    wishlistItems: getWishlistItemsFromLocalStorage() || [],
};

// Cart reducer to handle actions
const cartReducer = (state, action) => {
    switch (action.type) {
        // Cart Actions
        case 'ADD_TO_CART':
            return {
                ...state,
                cartItems: [...state.cartItems, action.payload],
            };
        case 'REMOVE_FROM_CART':
            return {
                ...state,
                cartItems: state.cartItems.filter(item => item.id !== action.payload),
            };
        case 'UPDATE_QUANTITY':
            return {
                ...state,
                cartItems: state.cartItems.map(item =>
                    item.id === action.payload.id
                        ? { ...item, quantity: action.payload.quantity }
                        : item
                ),
            };
        
        // Wishlist Actions
        case 'ADD_TO_WISHLIST':
            return {
                ...state,
                wishlistItems: [...state.wishlistItems, action.payload],
            };
        case 'REMOVE_FROM_WISHLIST':
            return {
                ...state,
                wishlistItems: state.wishlistItems.filter(item => item.id !== action.payload),
            };
        default:
            return state;
    }
};

// Cart provider component
export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    }, [state.cartItems]); // Update local storage when cart items change

    useEffect(() => {
        localStorage.setItem('wishlistItems', JSON.stringify(state.wishlistItems));
    }, [state.wishlistItems]); // Update local storage when wishlist items change

    const removeFromCart = (id) => {
        dispatch({ type: 'REMOVE_FROM_CART', payload: id });
    };

    const removeFromWishlist = (id) => {
        dispatch({ type: 'REMOVE_FROM_WISHLIST', payload: id });
    };

    return (
        <CartContext.Provider value={{ state, dispatch, removeFromCart, removeFromWishlist }}>
            {children}
        </CartContext.Provider>
    );
};

// Custom hook to use the CartContext
export const useCart = () => {
    return useContext(CartContext);
};
