import React from 'react';
import { useCart } from './cartcontext'; // Use the same context
import { FaTrash } from 'react-icons/fa';
import './wishlist.css'; // Optional CSS

function Wishlist() {
    const { state, dispatch } = useCart();
    const { wishlistItems } = state; // Get items from wishlist state

    const handleRemoveFromWishlist = (id) => {
        dispatch({ type: 'REMOVE_FROM_WISHLIST', payload: id });
    };

    return (
        <div className="wishlist-container">
            <h2>Your Wishlist</h2>
            {wishlistItems.length === 0 ? (
                <p>Your wishlist is empty!</p>
            ) : (
                <div className="wishlist-items">
                    {wishlistItems.map(item => (
                        <div className="wishlist-item" key={item.id}>
                            
                            <img src={item.images[0]} alt={item.productName} className="wishlist-item-image" />
                            <div className="wishlist-item-details">
                                <h4>{item.productName}</h4>
                                <p>{item.description}</p>
                                <p>Price: Rs. {item.price}</p>
                            
                                <button onClick={() => handleRemoveFromWishlist(item.id)} className="remove-button">
                                    <FaTrash /> Remove
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default Wishlist;
