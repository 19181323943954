import SubNavbar from "./subnavbar";
import './about.css';
import Card from 'react-bootstrap/Card';

function About() {
    return(
        <div className="about">
            <SubNavbar/>

            <div className="banner-container">
        <img src="/assets/image 1.png" className="img-fluid" alt="Banner" />
      </div>

      <div className="container-fluid mt-4">
        <div className="row ">
            <div className="col-md-6">
            <Card style={{height: '220px', borderTop: '5px solid yellow', backgroundColor: '#E4E4E4'}}>
      <Card.Body>
        <Card.Title style={{fontSize: '40px', fontWeight: '600'}}>Our Vision</Card.Title>
        <Card.Subtitle className="mb-2 mt-3 text-muted">Amuser Mart was founded with a simple yet powerful vision: to create a vibrant marketplace where creativity thrives, connections are made, and every purchase brings a smile. <br></br> We believe in the power of diversity and individuality, and we're passionate about supporting small businesses and artisans who pour their hearts into their craft.
        </Card.Subtitle>
        
      
      </Card.Body>
    </Card>
            </div>
            <div className="col-md-6">
            <Card style={{height: '220px', borderTop: '5px solid yellow', backgroundColor: '#E4E4E4'}}>
      <Card.Body>
        <Card.Title style={{fontSize: '40px', fontWeight: '600'}}>Discover Limitless Possibilities</Card.Title>
        <Card.Subtitle className="mb-2 mt-3 text-muted">Whether you're searching for handcrafted jewelry, artisanal home decor, stylish fashion accessories, or innovative gadgets, you'll find it all and more at Amuser Mart. Our curated collection spans a wide range of categories, ensuring that there's something for everyone and every occasion.</Card.Subtitle>
       
      </Card.Body>
    </Card>
            </div>
        </div>

      </div>

      <div className="container-fluid mt-4">
        <div className="row" style={{backgroundColor: 'rgba(0, 169, 180, 0.15)'}}>
            <div className="col-md-6">
                <img src="/assets/image 4.png" alt="img" className="img-fluid" style={{width: '100%'}}/>
            </div>

            <div className="col-md-6">
         
    <h1 className="pt-5">Connecting People, Empowering Dreams</h1>
    <p className="mt-5">At the heart of Amuser Mart is a community of passionate sellers and enthusiastic shoppers who share a love for quality, creativity, and exploration. We're more than just a marketplace; we're a platform for connections, collaborations, and dreams to flourish. With every purchase you make, you're not just supporting a small business—you're becoming part of a larger story of empowerment and inspiration.</p>
            </div>

        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row" style={{backgroundColor: 'rgba(0, 169, 180, 0.15)'}}>
            <div className="col-md-6">
        
                <h1 className="pt-5">Exceptional Service, Every Step of the Way</h1>
                <p className="mt-5">Your satisfaction is our top priority at Amuser Mart. From browsing our curated collections to placing your order and receiving your package, we're committed to providing you with a seamless and enjoyable shopping journey. Our dedicated customer support team is always here to assist you with any questions or concerns, ensuring that your experience with us is nothing short of exceptional.</p>
            </div>

            <div className="col-md-6">
            <img src="/assets/image 5.png" alt="img" className="img-fluid" style={{width: '100%'}}/>
   
            </div>

        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
            <div className="col-md-12">
                <h1>Join the Amuser Mart Family</h1>
            </div>
            <div className="col-md-12">
                <p>Whether you're a shopper in search of something special or a vendor looking to share your creations with the world, we invite you to join the Amuser Mart family. 
                Let's celebrate creativity, embrace diversity, and make every moment a little more joyful, one delightful discovery at a time.</p>
            </div>

            <div className="col-md-12">
                <p style={{color: '#00A9B4'}}>Thank you for choosing Amuser Mart—we can't wait to embark on this exciting journey with you!</p>
            </div>
        </div>

      </div>
        </div>
    )
};

export default About;