import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaHeart, FaShoppingCart, FaUser } from "react-icons/fa";
import { useCart } from './cartcontext'; // Import your cart context
import { Dropdown } from 'react-bootstrap'; // Import Dropdown from react-bootstrap
import { useAuth } from '../../AuthContext';

function MyNavbar() {
    const { state } = useCart(); // Access the cart state
    const { cartItems, wishlistItems } = state; // Destructure items from state
    const cartCount = cartItems.length; // Get the number of items in the cart
    const wishlistCount = wishlistItems.length; // Get the number of items in the wishlist
    const { isAuthenticated,  logout } = useAuth();
    return (
        <Navbar collapseOnSelect expand="lg" className="navbar-custom" style={{ backgroundColor: '#F5F5F5' }}>
            <Container>
                <Navbar.Brand href="/">
                    <img src="/assets/logo.jpg" alt="Logo" width={100} height={70} />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href="/" style={{ color: '#000', fontWeight: '600' }}>Home</Nav.Link>
                        <Nav.Link href="/#/about" style={{ color: '#000', fontWeight: '600' }}>About Us</Nav.Link>
                        <Nav.Link href="/#/contact" style={{ color: '#000', fontWeight: '600' }}>Contact Us</Nav.Link>
                        <Nav.Link href="/#/toys" style={{ color: '#000', fontWeight: '600' }}>Blog</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        <Nav.Link href="/#/wishlist" style={{ position: 'relative' }}>
                            <FaHeart style={{ color: '#000', fontWeight: '600' }} />
                            {wishlistCount > 0 && (
                                <span className="badge bg-danger" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
                                    {wishlistCount}
                                </span>
                            )}
                        </Nav.Link>
                        <Nav.Link href="/#/cart" style={{ position: 'relative' }}>
                            <FaShoppingCart style={{ color: '#000', fontWeight: '600' }} />
                            {cartCount > 0 && (
                                <span className="badge bg-danger" style={{ position: 'absolute', top: '-5px', right: '-10px' }}>
                                    {cartCount}
                                </span>
                            )}
                        </Nav.Link>
                        <Dropdown>
                            <Dropdown.Toggle variant="link" id="user-dropdown" style={{ color: '#000', fontWeight: '600' }}>
                                <FaUser />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                <Dropdown.Item href="/#/profile">My Account</Dropdown.Item>
                                <Dropdown.Item href="/#/orders">My Orders</Dropdown.Item>
                                {isAuthenticated ? (
                                <Dropdown.Item onClick={logout}>Log Out</Dropdown.Item>
                              ) : (
                                <Dropdown.Item href="/#/login">Login/SignUp</Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNavbar;
