import React from 'react';
import SubNavbar from './subnavbar';
import './signup.css'; // Import CSS file for styling
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import PersonOutlineOutlined from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

function Signup() {
    const [showPassword, setShowPassword] = React.useState(false);
    const [formValues, setFormValues] = React.useState({
      name: '',
      email: '',
      number: '',
      password: '',
      confirmPassword: '',
      
    });
    const [error, setError] = React.useState('');
    const navigate = useNavigate(); // Initialize useNavigate
    const { signup } = useAuth(); // Get signup function from useAuth
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => event.preventDefault();
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormValues({ ...formValues, [name]: value });
    };
  
    const handleSubmit = async () => {
      // Validate inputs
      if (formValues.password !== formValues.confirmPassword) {
        setError('Passwords do not match');
        return;
      }
  
      try {
        const response = await fetch(`${process.env.REACT_APP_PUBLIC_BASE_URL}/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formValues),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Signup failed');
        }
  
        const data = await response.json();
        console.log('Signup successful:', data);
  
        const userData = data.newUser || data.user;
        localStorage.setItem(
          "token",
          JSON.stringify(data.token)
        );
        localStorage.setItem(
          "user",
          JSON.stringify(userData)
        );
        // Handle successful signup, e.g., redirect to login page
  
         // Redirect to subscription page using navigate
         signup(); // Set the user as authenticated
         
         navigate('/');
      } catch (error) {
        setError(error.message);
      }
    };
    return (
      <div className='login'>
        <SubNavbar />
        <div className="login-container">
            
            <div className="form-box">
                <h1>SignUp</h1>
                <Grid item container xs={12} md={12}>
        <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-input-with-icon-adornment">Enter your name</InputLabel>
          <OutlinedInput
            id="outlined-input-with-icon-adornment"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            endAdornment={
              <InputAdornment position="end">
                <PersonOutlineOutlined />
              </InputAdornment>
            }
            label="Enter your name"
            sx={{ backgroundColor: '#FFF' }}
          />
        </FormControl>
      </Grid>
      <Grid item container xs={12} md={12}>
        <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-input-with-icon-adornment">Enter your email</InputLabel>
          <OutlinedInput
            id="outlined-input-with-icon-adornment"
            name="email"
            value={formValues.email}
            onChange={handleInputChange}
           
            label="Enter your email"
            sx={{ backgroundColor: '#FFF' }}
          />
        </FormControl>
      </Grid>
     
      <Grid item container xs={12} md={12}>
        <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-input-with-icon-adornment">Enter your number</InputLabel>
          <OutlinedInput
            id="outlined-input-with-icon-adornment"
            name="number"
            value={formValues.number}
            onChange={handleInputChange}
           
            label="Enter your number"
            sx={{ backgroundColor: '#FFF' }}
          />
        </FormControl>
      </Grid>
     
  
      <Grid item container xs={12} md={12}>
        <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Set Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={formValues.password}
            onChange={handleInputChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Set Password"
            sx={{ backgroundColor: '#FFF' }}
          />
        </FormControl>
      </Grid>
      <Grid item container xs={12} md={12}>
        <FormControl sx={{ m: 1 }} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            name="confirmPassword"
            type={showPassword ? 'text' : 'password'}
            value={formValues.confirmPassword}
            onChange={handleInputChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Confirm Password"
            sx={{ backgroundColor: '#FFF' }}
          />
        </FormControl>
      </Grid>
      {error && (
        <Grid item xs={12}>
          <Box sx={{ color: 'red', mt: 1 }}>{error}</Box>
        </Grid>
      )}
      <Grid item container xs={12} md={12} className='mt-3'>
        <Button
          variant="contained"
          fullWidth
          sx={{ borderRadius: '20px', backgroundColor: '#738DE7', fontSize: '20px', color: '#FFF', fontWeight: '600' }}
          onClick={handleSubmit}
        >
          SIGN UP
        </Button>
      </Grid>
            </div>
        </div>
      </div>
    );
}

export default Signup;
