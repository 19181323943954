import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SubNavbar from "./subnavbar";
import axios from 'axios';
import { useCart } from './cartcontext'; // Import the useCart hook
import { Button } from 'react-bootstrap'; // Import Button from react-bootstrap
import { FaHeart } from 'react-icons/fa';
import { useAuth } from '../../AuthContext';

function ProductDetail() {
    const { id } = useParams(); // Get the product ID from the URL
    const [product, setProduct] = useState(null); // State to store product details
    const { dispatch, state } = useCart(); // Access dispatch from the cart context
    const [quantity, setQuantity] = useState(1); // State for quantity, starting at 1
    const [isWished, setIsWished] = useState(false); // Initial state for wishlist
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    const [amount, setAmount] = useState(1000); // Example amount
    const [orderId, setOrderId] = useState('ORDER123');

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${id}`); // Fetch product by ID
                setProduct(response.data); // Store product data in state
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        fetchProduct();
    }, [id]); // Only fetch when the ID changes

    useEffect(() => {
        if (product) {
            // Check if the product is in the wishlist
            setIsWished(state.wishlistItems.some(item => item.id === product._id));
        }
    }, [product, state.wishlistItems]); // Run when product or wishlist changes

    const addToCart = () => {
        if(isAuthenticated) {
        if (product) {
            const productWithFullImageURL = {
                ...product,
                id: product._id,
                quantity,
                images: product.images.map(img => {
                    return img.startsWith("http") ? img : `${process.env.REACT_APP_PUBLIC_BASE_URL}/uploads/${img}`;
                })
            };
            dispatch({ type: 'ADD_TO_CART', payload: productWithFullImageURL });
        }
    } else {
        navigate('/login')
    }
    };

    const handleAddToWishlist = () => {
        if (isAuthenticated) {
            if (!isWished) {
                dispatch({ type: 'ADD_TO_WISHLIST', payload: { ...product, id: product._id } });
                setIsWished(true);
                console.log(`Added to wishlist: ${product.productName}`);
            }
        } else {
            navigate('/login'); // Redirect to login if not authenticated
        }
    };

    const handleRemoveFromWishlist = () => {
        dispatch({ type: 'REMOVE_FROM_WISHLIST', payload: product._id });
        setIsWished(false);
        console.log(`Removed from wishlist: ${product.productName}`);
    };

    // Increment quantity
    const incrementQuantity = () => {
        setQuantity(prev => prev + 1);
    };

    // Decrement quantity
    const decrementQuantity = () => {
        setQuantity(prev => Math.max(prev - 1, 1)); // Ensure quantity doesn't go below 1
    };

    const handleCheckout = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_PUBLIC_BASE_URL}/checkout`, { amount, orderId });
          const { encryptedData, accessCode, url } = response.data;
    
          // Prepare the form to submit the encrypted data
          const form = document.createElement('form');
          form.method = 'POST';
          form.action = url;
    
          // Extracting the content and iv from encryptedData
          const inputEncryptedContent = document.createElement('input');
          inputEncryptedContent.type = 'hidden';
          inputEncryptedContent.name = 'enc_request'; // This may need to be 'enc_data' based on CCAvenue's requirements
          inputEncryptedContent.value = `${encryptedData.content}`; // Sending the content
    
          const inputIV = document.createElement('input');
          inputIV.type = 'hidden';
          inputIV.name = 'iv'; // If required by CCAvenue, otherwise remove this
          inputIV.value = `${encryptedData.iv}`; // Sending the iv, if needed
    
          const inputAccessCode = document.createElement('input');
          inputAccessCode.type = 'hidden';
          inputAccessCode.name = 'access_code';
          inputAccessCode.value = accessCode;
    
          form.appendChild(inputEncryptedContent);
          form.appendChild(inputIV);
          form.appendChild(inputAccessCode);
          document.body.appendChild(form);
          form.submit(); // Submit the form to CCAvenue
        } catch (error) {
          console.error('Checkout error:', error);
        }
      };
      

    if (!product) return <div>Loading...</div>; // Show loading message while fetching

    return (
        <div>
            <SubNavbar />
            <div className="container row mt-5">
                <div className="col-md-6">
                    <img 
                        src={product.images[0]} // Use the image URL directly from the product data
                        alt={product.productName} 
                        className="img-fluid" 
                    />
                </div>
                <div className="col-md-6">
                    <h1>{product.productName}</h1>
                    <h3>&#8377; {product.price}</h3>
                    <div className="quantity-container d-flex align-items-center p-2 my-3">
                        <span className="me-2">Quantity:</span>
                        <div className='operator-container border d-flex align-items-center'>
                            <Button onClick={decrementQuantity} style={{backgroundColor: '#FFF', borderColor: '#FFF', color: '#000'}}>−</Button>
                            <span className="mx-2">{quantity}</span>
                            <Button onClick={incrementQuantity} style={{backgroundColor: '#FFF', borderColor: '#FFF', color: '#000'}}>+</Button>
                        </div>
                    </div>
                    <Button onClick={addToCart} style={{backgroundColor: '#FFF', borderColor: '#000', color: '#000', fontWeight: '700', fontSize: '30px'}}>ADD TO CART</Button> 

                    <Button onClick={handleCheckout} className='mt-2' style={{backgroundColor: 'rgba(255, 215, 0, 0.82)', borderColor: 'rgba(255, 215, 0, 0.82)', color: '#FFF', fontWeight: '700', fontSize: '30px'}}>BUY NOW</Button> 

                    <Button onClick={isWished ? handleRemoveFromWishlist : handleAddToWishlist} variant={isWished ? "danger" : "outline-danger"}>
                        <FaHeart /> {isWished ? "Remove from Wishlist" : "Add to Wishlist"}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ProductDetail;
