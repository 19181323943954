// SubNavbar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { IoSearch } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";

function SubNavbar() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = () => {
    // Redirect to the SearchResults component with search term as query parameter
    navigate(`/search?query=${searchTerm}`);
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-custom" style={{ backgroundColor: '#008080' }}>
      <Container>
        <Navbar.Brand href="/" style={{ color: '#FFF' }}>
          Shop By Department <IoIosArrowForward />
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <InputGroup>
              <InputGroup.Text id="basic-addon1"><IoSearch /></InputGroup.Text>
              <Form.Control
                placeholder="All Categories"
                aria-label="All Categories"
                aria-describedby="basic-addon1"
                value={searchTerm}
                onChange={handleSearchChange}
              />
              <InputGroup.Text
                id="basic-addon2"
                style={{ backgroundColor: "#FFD700", cursor: "pointer" }}
                onClick={handleSearchSubmit}
              >
                Search
              </InputGroup.Text>
            </InputGroup>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default SubNavbar;
