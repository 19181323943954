import SubNavbar from "./subnavbar";
import Card from 'react-bootstrap/Card';
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdChatbubbles } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { Ri24HoursFill } from "react-icons/ri";

function Contact() {
    return(
        <div className="contact">
            <SubNavbar/>

            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Contact Us</h1>
                    </div>
                    <div className="col-md-12">
                        <p>We're here to help! Whether you have a question, need assistance, or just want to say hello, we'd love to hear from you. 
                        Reach out to us using any of the following methods:</p>
                    </div>

                </div>
            </div>

            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-md-4">
                    <Card style={{height: '270px', borderRight: '5px solid #00A9B4', backgroundColor: '#EEE'}}>
                        <Card.Body>
                            <Card.Title><img src="/assets/image 6.png" alt="img" className="img-fluid"/></Card.Title>
                            <Card.Title style={{fontSize: '25px', fontWeight: '600'}}>Customer Support</Card.Title>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><MdEmail/> <a href="mailto:amusermart@gmail.com">amusermart@gmail.com</a></Card.Subtitle>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><FaPhoneAlt/><a href="tel:9462710919">+91 9462710919</a></Card.Subtitle>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><IoMdChatbubbles/>Live chat support available on our website during business hours</Card.Subtitle>
                        </Card.Body>
                    </Card>
                    </div>
                    <div className="col-md-4">
                    <Card style={{height: '270px', borderRight: '5px solid #00A9B4', backgroundColor: '#EEE'}}>
                        <Card.Body>
                            <Card.Title><img src="/assets/image 7.png" alt="img" className="img-fluid"/></Card.Title>
                            <Card.Title style={{fontSize: '25px', fontWeight: '600'}}>Vendor Enquiries</Card.Title>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><MdEmail/> <a href="mailto:amusermart@gmail.com">amusermart@gmail.com</a></Card.Subtitle>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><FaPhoneAlt/><a href="tel:7426911355">+91 7426911355</a></Card.Subtitle>
                        
                        </Card.Body>
                    </Card>
                    </div>
                    <div className="col-md-4">
                    <Card style={{height: '270px', borderRight: '5px solid #00A9B4', backgroundColor: '#EEE'}}>
                        <Card.Body>
                            <Card.Title><img src="/assets/image 8.png" alt="img" className="img-fluid"/></Card.Title>
                            <Card.Title style={{fontSize: '25px', fontWeight: '600'}}>Head Office</Card.Title>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><FaLocationDot/>Amuser Technologies LLP D-41 Tara Nagar, Jhotwara,Jaipur-302012, Rajasthan</Card.Subtitle>
                            <Card.Subtitle className="mb-2 mt-3 text-muted"><Ri24HoursFill/>Operating Hours available from  Monday - Saturday: 10:00 AM - 5:00 PM (IST)</Card.Subtitle>
                        
                        </Card.Body>
                    </Card>
                    </div>
                </div>
            </div>
            
            <div className="container mt-4" style={{borderRadius: '8px', boxShadow: '0px 0px 14.1px 0px rgba(0, 0, 0, 0.11)', backgroundColor: '#F2F2F2'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <h1>
                            Connect With Us
                            </h1>
                            <p>
                            Follow us on social media for updates, promotions, and inspiration:
                            </p>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-md-4">
                                    <img src="/assets/image 11.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="col-md-4">
                                <img src="/assets/image 12.png" alt="img" className="img-fluid"/>
                                </div>
                                <div className="col-md-4">
                                <img src="/assets/twitter.png" alt="img" className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container-fluid mt-4">
        <div className="row ">
            <div className="col-md-6">
            <Card style={{height: '270px', borderRight: '5px solid #00A9B4', backgroundColor: '#EEE'}}>
      <Card.Body>
        <Card.Title><img src="/assets/image 13.png" alt="img" className="img-fluid"/></Card.Title>
        <Card.Title style={{fontSize: '40px', fontWeight: '600'}}>Feedbacks And Suggestions</Card.Title>
        <Card.Subtitle className="mb-2 mt-3 text-muted">We value your feedback! If you have any suggestions for how we can improve our website or services,
        Please send us an email at amusermart@gmail.com
        </Card.Subtitle>
        
      
      </Card.Body>
    </Card>
            </div>
            <div className="col-md-6">
            <Card style={{height: '270px', borderRight: '5px solid #00A9B4', backgroundColor: '#EEE'}}>
      <Card.Body>
      <Card.Title><img src="/assets/image 14.png" alt="img" className="img-fluid"/></Card.Title>
        <Card.Title style={{fontSize: '40px', fontWeight: '600'}}>Stay In Touch</Card.Title>
        <Card.Subtitle className="mb-2 mt-3 text-muted">Subscribe to our newsletter to stay up-to-date with the latest arrivals, special offers, and more. Sign up at the bottom of our website or during checkout.</Card.Subtitle>
       
      </Card.Body>
    </Card>
            </div>
        </div>

      </div>

      <div className="container mt-4">
        <div className="row">
            <div className="col-md-12">
                <p style={{color: '#00A9B4'}}>Thank you for choosing Amuser Mart. We're grateful for your support and look forward to serving you!</p>
            </div>
        </div>
      </div>

        </div>
    )
};

export default Contact;