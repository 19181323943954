import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import {
    TextField,
    Button,
    Container,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Checkbox,
    ListItemText,
} from '@mui/material';
import Grid from '@mui/material/Grid2';

function EditProduct() {
    const { id } = useParams();
    const [product, setProduct] = useState({
        productName: '',
        description: '',
        category: [],
        SKU: '',
        price: '',
        discount: '',
        stockQuantity: '',
        minOrderQuantity: '',
        shippingOption: '',
        images: []
    });
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${id}`);
                setProduct(response.data);
            } catch (error) {
                console.error('Error fetching product details:', error);
            }
        };

        const fetchCategories = () => {
            const storedCategories = JSON.parse(localStorage.getItem('categories')) || [
                "Featured", "On Sale", "Top Rated", "Best Seller", "Limited Edition"
            ];
            setCategories(storedCategories);
        };

        fetchProduct();
        fetchCategories();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({ ...product, [name]: value });
    };

    const handleCategoryChange = (e) => {
        const {
            target: { value },
        } = e;
        setProduct({ ...product, category: typeof value === 'string' ? value.split(',') : value });
    };

    const handleNewCategoryChange = (e) => {
        setNewCategory(e.target.value);
    };

    const handleAddNewCategory = () => {
        if (newCategory && !categories.includes(newCategory)) {
            const updatedCategories = [...categories, newCategory];
            setCategories(updatedCategories);
            setProduct((prevProduct) => ({
                ...prevProduct,
                category: [...prevProduct.category, newCategory]
            }));
            localStorage.setItem('categories', JSON.stringify(updatedCategories));
            setNewCategory(''); // Clear the new category input
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_PUBLIC_BASE_URL}/products/${id}`, product);
            navigate('/manageproducts');
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" gutterBottom>Edit Product</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="productName"
                            value={product.productName}
                            onChange={handleChange}
                            label="Product Name"
                            variant="outlined"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="description"
                            value={product.description}
                            onChange={handleChange}
                            label="Description"
                            variant="outlined"
                            required
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="SKU"
                            value={product.SKU}
                            onChange={handleChange}
                            label="SKU"
                            variant="outlined"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="price"
                            type="number"
                            value={product.price}
                            onChange={handleChange}
                            label="Price"
                            variant="outlined"
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                multiple
                                value={product.category}
                                onChange={handleCategoryChange}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                {categories.map((category) => (
                                    <MenuItem key={category} value={category}>
                                        <Checkbox checked={product.category.includes(category)} />
                                        <ListItemText primary={category} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Add New Category"
                            value={newCategory}
                            onChange={handleNewCategoryChange}
                        />
                        <Button 
                            variant="contained" 
                            color="secondary" 
                            onClick={handleAddNewCategory} 
                            style={{ marginTop: '10px' }}
                        >
                            Add Category
                        </Button>
                    </Grid>
                </Grid>
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    style={{ marginTop: '20px' }}
                >
                    Update Product
                </Button>
            </form>
        </Container>
    );
}

export default EditProduct;
