import Button from 'react-bootstrap/Button';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Import useLocation to access passed state

function ProductSuccess() {
    const location = useLocation();
    const navigate = useNavigate(); // Initialize useNavigate
    const { formData } = location.state || {}; // Destructure formData from state

    if (!formData) {
        return <div>No product information available.</div>;
    }

    return (
        <div className="productsuccess">
            <div className="container">
                Your product has been uploaded successfully and is now awaiting approval.
            </div>
            <div className="container mt-3" style={{border: '2px solid #5C5C5C', borderRadius: '8px'}}>
                <div className="row">
                    <div className="col-md-6">
                        <p><strong>Product Name:</strong> {formData.productName}</p>
                        <p><strong>Category:</strong> {formData.category}</p>
                        <p><strong>Price:</strong> ₹{formData.price}</p>
                        <p><strong>Description:</strong> {formData.description}</p>
                        <p><strong>SKU:</strong> {formData.SKU}</p>
                        <p><strong>Stock Quantity:</strong> {formData.stockQuantity}</p>
                        <p><strong>Discount:</strong> {formData.discount}%</p>
                        <p><strong>Shipping Option:</strong> {formData.shippingOption}</p>
                    </div>
                    <div className="col-md-6">
                        <strong>Uploaded Images:</strong>
                        {formData.images && Array.from(formData.images).map((image, index) => (
                            <div key={index} style={{ marginBottom: '10px' }}>
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Product Image ${index + 1}`}
                                    style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className='container mt-5'>
            Your product will be reviewed by our team to ensure it meets our listing guidelines. 
            <br></br>
This process usually takes between 24-48 hours. 
<br></br>
You will receive a notification once your product has been approved and is live on our website.
<br></br>
Thank you for your patience!
            </div>

            <div className='container mt-3'>
                        <h4>Next Steps</h4>
                        <ul>
                            <li>You can view the status of your product in manage product page.</li>
                            <li>
                            If you need to make any changes to your product listing, please visit the manage product page.
                            </li>
                        </ul>
                        <div className='row'>
                        <div className='col-md-3'>
                        <Button onClick={() => navigate('/productlisting')}>Add Another Product</Button>
                    </div>
                    <div className='col-md-3'>
                        <Button onClick={() => navigate('/dashboard')}>Back To Home</Button>
                    </div>
                        </div>
            </div>

            
        </div>
    );
}

export default ProductSuccess;
