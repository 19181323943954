import React from 'react';
import SubNavbar from "./subnavbar";
import { useCart } from './cartcontext'; // Import the useCart hook
import { Button } from 'react-bootstrap'; // Import Button from Bootstrap
import { FaTimes } from "react-icons/fa"; // Import cross icon from react-icons

function Cart() {
    const { state, removeFromCart, dispatch } = useCart(); // Access cart state and removeFromCart function
    const { cartItems: items } = state; // Rename 'cartItems' to 'items'


    const totalAmount = (items || []).reduce((total, item) => total + item.price * item.quantity, 0);


    const updateQuantity = (id, newQuantity) => {
        if (newQuantity < 1) return; // Prevent setting quantity below 1
        dispatch({ type: 'UPDATE_QUANTITY', payload: { id, quantity: newQuantity } });
    };

    return (
        <div>
            <SubNavbar />
            <div className="container-fluid row mt-5">
                <div className="col-md-6">
                    {items.length > 0 ? (
                        items.map((item) => (
                            <div key={item.id} className="card mb-3">
                                <img 
                                    src={item.images[0]?.startsWith("http") ? item.images[0] : `${process.env.REACT_APP_PUBLIC_BASE_URL}/uploads/${item.images[0]}`} 
                                    alt={item.productName} 
                                    className="card-img-top" 
                                    style={{ width: '150px', objectFit: 'cover', borderRadius: '5px 0 0 5px' }}
                                />
                                <div className="card-body">
                                    <h5 className="card-title">{item.productName}</h5>
                                    <p className="card-text">Price: Rs. {item.price}</p>
                                    
                                    <div className="d-flex align-items-center">
                                        <Button 
                                            variant="outline-secondary" 
                                            onClick={() => updateQuantity(item.id, item.quantity - 1)} 
                                            disabled={item.quantity <= 1}
                                        >
                                            -
                                        </Button>
                                        <span className="mx-2">{item.quantity}</span>
                                        <Button 
                                            variant="outline-secondary" 
                                            onClick={() => updateQuantity(item.id, item.quantity + 1)}
                                        >
                                            +
                                        </Button>
                                    </div>
                                    
                                    <p className="card-text">Total: Rs. {(item.price * item.quantity).toFixed(2)}</p> {/* Show total for this item */}
                                    <button 
                                        className="btn btn-danger" 
                                        onClick={() => removeFromCart(item.id)} // Pass item.id directly to removeFromCart
                                    >
                                        <FaTimes /> Remove
                                    </button>
                                </div>
                            </div>
                        ))
                    ) : (
                        <h5>Your cart is empty.</h5>
                    )}
                </div>

                <div className="col-md-6">
                    <h2>Order Summary</h2>
                    <p>Sub Total - Rs. {totalAmount.toFixed(2)}</p>
                    <p>Discount - Rs. 0.00</p> {/* You can implement discount logic here */}
                    <hr />
                    <h5>Total Amount - Rs. {totalAmount.toFixed(2)}</h5>
                    <Button>
                        Checkout Now
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Cart;
