import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import SubNavbar from './subnavbar';

function SearchResults() {
  const [products, setProducts] = useState([]);
  const location = useLocation();

  // Extract search term from URL query
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('query') || '';

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PUBLIC_BASE_URL}/products`);
        const filteredProducts = response.data.filter((product) => {
          // Ensure category is a string or an array and check against the search term
          if (Array.isArray(product.category)) {
            // If category is an array, check if any of the categories match the search term
            return product.category.some((cat) =>
              cat.toLowerCase().includes(searchTerm.toLowerCase())
            );
          } else if (typeof product.category === 'string') {
            // If category is a string, check if it matches the search term
            return product.category.toLowerCase().includes(searchTerm.toLowerCase());
          }
          return false;
        });
        setProducts(filteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, [searchTerm]);

  return (
    <div className="search-results">
         <SubNavbar />
      <h2>Search Results for "{searchTerm}"</h2>
      <div className="products-container">
        {products.length > 0 ? (
          products.map((product) => (
            <Link key={product._id} to={`/product/${product._id}`} className="product-item" style={{ textDecoration: 'none' }}>
              <img src={product.images[0]} alt={product.productName} className="product-image" />
              <p className="product-name" style={{ color: '#000' }}>{product.productName}</p>
            </Link>
          ))
        ) : (
          <p>No products found for "{searchTerm}"</p>
        )}
      </div>
    </div>
  );
}

export default SearchResults;
